import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { cn, formatCurrencyK1, formatPercent } from "helpers";
import { Fragment, useRef, useState } from "react";
import { isDesktop } from "react-device-detect";

type Props = {
  label: string;
  variable: string;
  unit?: string | null;
  tag?: "new" | null;
  filters: any;
  options: number[];
  equityRangeSet: (min: number | null, max: number | null) => void;
};

export const FilterMinMaxMenu = ({
  label,
  variable,
  unit = "",
  tag = null,
  filters,
  options = [],
  equityRangeSet,
}: Props) => {
  const min_value = filters[`min_${variable}`];
  const max_value = filters[`max_${variable}`];

  let buttonTittle = "";
  if (min_value !== null && max_value !== null) {
    buttonTittle = `${formatPercent(min_value / 100, 0)} - ${formatPercent(max_value / 100, 0)}`;
  } else if (variable === "equity" && min_value === 100) {
    buttonTittle = `${formatPercent(min_value / 100, 0)}`;
  } else if (min_value !== null) {
    buttonTittle = `${formatPercent(min_value / 100, 0)}+`;
  } else if (max_value !== null) {
    buttonTittle = `Up to ${formatPercent(max_value / 100, 0)}`;
  }

  const inputRef = useRef<any>();
  const [customValue, setCustomValue] = useState<number | null>(null);

  return (
    <>
      {isDesktop && (
        <Menu
          as="div"
          className={cn(
            "relative ml-2 mr-1 hidden md:mx-2",
            variable === "gross_gain" && "filter-gain:flex",
            variable === "gross_income" && "filter-income:flex",
            variable === "equity" && "filter-equity:flex",
          )}
        >
          {({ open, close }) => (
            <>
              <Menu.Button
                className={`text-md flex items-center justify-between rounded-md border border-gray-300 py-2 pl-3 pr-2 text-left text-sm font-medium ${
                  min_value !== null || max_value !== null ? "bg-black text-white" : "text-st-light"
                }`}
              >
                <span className="flex items-center whitespace-nowrap">
                  {min_value !== null || max_value !== null ? <>{buttonTittle}</> : <>{label}</>}
                </span>
                {open ? (
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                ) : (
                  <>
                    {min_value !== null || max_value !== null ? (
                      <XIcon
                        className="ml-2 h-4 w-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          equityRangeSet(null, null);
                          setCustomValue(null);
                        }}
                      />
                    ) : (
                      <ChevronDownIcon className="ml-2 h-4 w-4 text-st-darkest" />
                    )}
                  </>
                )}
              </Menu.Button>

              {/* Tag. Another tags types can be added in the future */}
              {tag && !(min_value !== null || max_value !== null) && tag === "new" && (
                <div className="absolute -end-1 -top-2 inline-flex h-4 w-8 items-center justify-center rounded-sm border-red-500 bg-red-500 text-[10px] font-bold text-white">
                  NEW
                </div>
              )}

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute -top-2 z-30 mt-2 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div>
                    <input
                      ref={inputRef}
                      type="number"
                      className="h-10 w-full items-center rounded-t-md border border-dotted border-gray-400 p-1 text-right ring-1 ring-black ring-opacity-5 focus:border-gray-400 focus:placeholder-transparent focus:outline-none focus:ring-black"
                      placeholder={
                        customValue !== null
                          ? options.includes(customValue)
                            ? ""
                            : customValue.toString()
                          : ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value ? parseFloat(e.target.value) : null;

                        setCustomValue(newValue);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          if (
                            customValue !== null &&
                            ((max_value !== null && customValue > max_value) ||
                              customValue < options[0] ||
                              (max_value === null && customValue > options[options.length - 1]))
                          ) {
                            return;
                          }
                          equityRangeSet(customValue, max_value);
                          close();
                        }
                      }}
                    />
                    <div className="h-fit max-h-56 overflow-y-scroll rounded-md">
                      {options
                        .filter((option) => (max_value !== null ? option < max_value : true))
                        .map((option, idx) => (
                          <Menu.Item key={label + idx}>
                            <div className="text-center text-sm" key={`ggo-${option}`}>
                              <button
                                className={`m-1 w-24 p-1 text-center ${
                                  min_value === option
                                    ? "bg-[#6389c4] text-white"
                                    : "bg-white text-st-light hover:bg-[#6389c4] hover:text-white"
                                }`}
                                onClick={() => {
                                  setCustomValue(null);
                                  equityRangeSet(option === min_value ? null : option, max_value);
                                }}
                              >
                                {option === +Infinity
                                  ? `Any ${label}`
                                  : unit === "$"
                                    ? formatCurrencyK1(option)
                                    : option === 0
                                      ? `0${unit}`
                                      : `${option}${unit}`}
                              </button>
                            </div>
                          </Menu.Item>
                        ))}
                    </div>
                  </div>
                </Menu.Items>
                {/* <Menu.Items className="absolute top-8 z-30 mt-2 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="m-0 w-full p-0">
                    {options
                      .filter((val) => (max_value !== null ? val < max_value : true))
                      .map((val, idx) => (
                        <Menu.Item key={idx}>
                          <div className="text-center text-sm" key={`ggo-${val}`}>
                            <button
                              className={`m-1 w-24 p-1 text-center ${
                                min_value === val
                                  ? "bg-[#6389c4] text-white"
                                  : "text-st-light bg-white hover:bg-[#6389c4] hover:text-white"
                              }`}
                              onClick={() => equityRangeSet(val === min_value ? null : val, max_value)}
                            >
                              {val === 0 ? `0%` : variable === "equity" && val === 100 ? `100%` : `${val}%+`}
                            </button>
                          </div>
                        </Menu.Item>
                      ))}
                  </div>
                </Menu.Items> */}
              </Transition>
            </>
          )}
        </Menu>
      )}
    </>
  );
};
