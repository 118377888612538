import React, { useEffect, useRef } from "react";
import { atom, useSetRecoilState } from "recoil";
import { useSelectedProperty } from "state";
import { LeafletMapService } from "./LeafletMapService";
import "leaflet/dist/leaflet.css";
import { Spinner } from "components";
import { useSearchParamsState } from "react-use-search-params-state";
import { useRecoilValue } from "recoil";
import { useMultiPolygons, useUserSession } from "state";
import { mapBoundsDefaults, searchResultsAtom, useMapRequirements } from "state/browse";

export const highlightHandlerAtom = atom<CallableFunction | null>({
  key: "highlightHandlerAtom",
  default: null,
});

type Props = {
  emptyResult: boolean;
};

export const Map = ({ emptyResult }: Props) => {
  const searchResults = useRecoilValue(searchResultsAtom);
  const ref = useRef<HTMLDivElement>(null);
  const mapService = useRef<LeafletMapService | null>(null);
  // const appreciationPolygonsAddedRef = useRef(false);
  const { setSelectedProperty } = useSelectedProperty();
  const setHighlightHandler = useSetRecoilState(highlightHandlerAtom);
  const isClient = useUserSession().isClient;
  const mapRequirements = useMapRequirements();
  const setMultiPolygons = useMultiPolygons();
  const [mapBoundsParams, setMapBoundsParams] = useSearchParamsState(mapBoundsDefaults);

  useEffect(() => {
    if (!ref.current || mapService.current) return;

    mapService.current = new LeafletMapService(
      setSelectedProperty,
      emptyResult, // with emptyResult, we show only the appreciation layer
      isClient,
      mapRequirements,
    );
    mapService.current.createMap(ref.current);
    setHighlightHandler(() => mapService.current?.highlightHandler.bind(mapService.current));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    return () => {
      mapService.current?.clearMap();
      mapService.current = null;
    };
  }, []);

  useEffect(() => {
    if (mapService.current) {
      mapService.current.updateResults(searchResults, mapRequirements, mapBoundsParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  useEffect(() => {
    if (mapService.current) {
      mapService.current.setMultiPolygons = setMultiPolygons;
    }
  }, [setMultiPolygons]);

  useEffect(() => {
    if (mapService.current) {
      mapService.current.setMapBoundsParams = setMapBoundsParams;
    }
  }, [setMapBoundsParams]);

  return (
    <div ref={ref} className="h-full w-full">
      {mapRequirements.loading && (
        <div className="grid h-full w-full place-items-center">
          <span className="flex items-center rounded-3xl bg-[#61a28d] py-2 pl-8 pr-4 text-center text-white">
            <Spinner /> Loading map...
          </span>
        </div>
      )}
    </div>
  );
};
