import { Tab } from "@headlessui/react";
import { CostEditModal, FlipTab, RehabTab, RentalTab } from "components/proformas/financials/";
import { useRef, useState } from "react";
import { useSetVisibleSection } from "state";

export const Financials = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  useSetVisibleSection(ref, "financials");

  return (
    <div
      className="align-center flex w-full flex-col items-center justify-center border border-[#f2dfce] bg-[#FFF0E4] px-0 py-10 sm:py-[5.5rem]"
      id="Financials"
      ref={ref}
    >
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex w-80 max-w-sm space-x-0 p-0 sm:w-96">
          <Tab
            className={({ selected }) =>
              selected
                ? "w-full rounded-l-sm border border-[#38455b] bg-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 text-white"
                : "text-st-light w-full rounded-l-sm border border-[#eac6b1] bg-[#f2dfce] py-2.5 text-sm font-medium uppercase leading-5"
            }
          >
            {" "}
            Rehab
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "w-full border border-[#38455b] bg-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 text-white"
                : "text-st-light w-full border border-l-0 border-r-0 border-[#eac6b1] bg-[#f2dfce] py-2.5 text-sm font-medium uppercase leading-5 focus:ring-white"
            }
          >
            {" "}
            Resell
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "w-full rounded-r-sm border border-[#f2dfce] bg-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 text-white"
                : "text-st-light w-full rounded-r-sm border border-[#eac6b1] bg-[#f2dfce] py-2.5 text-sm font-medium uppercase leading-5 focus:ring-white"
            }
          >
            {" "}
            Rental
          </Tab>
        </Tab.List>
        <Tab.Panels className="flex w-full justify-center bg-gradient-to-b">
          <div className="w-full max-w-7xl justify-center px-4 pt-4">
            {/* tab content */}
            <Tab.Panel>
              <RehabTab />
            </Tab.Panel>
            <Tab.Panel>
              <FlipTab setSelectedTab={setSelectedTab} />
            </Tab.Panel>
            <Tab.Panel>
              <RentalTab />
            </Tab.Panel>
          </div>
        </Tab.Panels>
      </Tab.Group>

      <CostEditModal />
    </div>
  );
};
