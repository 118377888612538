import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { cn, formatCurrencyK1 } from "helpers";
import { Fragment, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

type Props = {
  placeholder?: string;
  label: string;
  value: number;
  unit?: string | null;
  filters: any;
  variable: string;
  options: number[];
  onChange: CallableFunction;
  hidePlaceholderWithValue?: boolean;
  className?: string;
};

export const FilterHybridDropdownMenu = ({
  placeholder,
  label,
  value,
  unit = "",
  filters,
  variable,
  options = [],
  onChange,
  hidePlaceholderWithValue,
  className,
}: Props) => {
  const min_value = filters[`min_${variable}`];
  const max_value = filters[`max_${variable}`];
  const inputRef = useRef<any>();
  const [customValue, setCustomValue] = useState<number | null>(null);

  return (
    <Menu as="div" className="relative flex w-full">
      {({ open, close }) => (
        <>
          <Menu.Button className={cn(className, "flex w-full justify-end py-2 pl-2 pr-1 sm:pl-3 sm:pr-2")}>
            <span className="flex items-center whitespace-nowrap text-st-light">
              {(isMobile || hidePlaceholderWithValue) && value !== null ? (
                ` ${value}${unit}`
              ) : value !== null ? (
                value === +Infinity ? (
                  `Any ${label}`
                ) : (
                  ` ${placeholder} ${unit === "$" ? formatCurrencyK1(value) : `${value}${unit}`}`
                )
              ) : (
                <span className="text-st-lightest">{placeholder}</span>
              )}
            </span>
            {!open && (
              <>
                {value !== null ? (
                  <XIcon
                    className="ml-2 size-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCustomValue(null);
                      onChange(null);
                    }}
                  />
                ) : (
                  <ChevronDownIcon className="ml-2 size-4" />
                )}
              </>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-30 flex w-full origin-top-left justify-end rounded-md shadow-lg">
              <div>
                <input
                  ref={inputRef}
                  type="number"
                  className="h-9 w-full items-center border border-dotted border-gray-400 p-1 text-right ring-1 ring-black ring-opacity-5 focus:border-gray-400 focus:placeholder-transparent focus:outline-none focus:ring-black"
                  placeholder={value !== null ? (options.includes(value) ? "" : value.toString()) : ""}
                  onChange={(e) => {
                    const newValue = e.target.value ? parseFloat(e.target.value) : null;
                    setCustomValue(newValue);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                      if (
                        customValue !== null &&
                        placeholder === "Min" &&
                        ((max_value !== null && customValue > max_value) ||
                          customValue < options[0] ||
                          (max_value === null && customValue > options[options.length - 1]))
                      ) {
                        return;
                      }
                      if (
                        customValue !== null &&
                        placeholder === "Max" &&
                        ((min_value !== null && customValue < min_value) ||
                          customValue > options[options.length - 1] ||
                          (min_value === null && customValue < options[0]))
                      ) {
                        return;
                      }

                      onChange(customValue);
                      close();
                    }
                  }}
                />
                <div className="h-fit max-h-56 overflow-y-scroll rounded-md">
                  {options.map((option, idx) => (
                    <Menu.Item key={label + idx}>
                      <button
                        className={cn(
                          "block w-full px-4 py-2 text-right text-sm",
                          value === option
                            ? "bg-[#6389c4] text-white"
                            : "bg-white text-st-light hover:bg-[#6389c4] hover:text-white",
                        )}
                        onClick={() => {
                          setCustomValue(null);
                          onChange(option === value ? null : option);
                        }}
                      >
                        {option === +Infinity
                          ? `Any ${label}`
                          : unit === "$"
                            ? formatCurrencyK1(option)
                            : option === 0
                              ? `0${unit}`
                              : `${option}${unit}`}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
